function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
/*!
* Bootstrap.js by @fat & @mdo
* Copyright 2012 Twitter, Inc.
* http://www.apache.org/licenses/LICENSE-2.0.txt
*/

import jQuery from 'jquery';
!function (e) {
  e(function () {
    "use strict";

    e.support.transition = function () {
      var e = function () {
        var e = document.createElement("bootstrap"),
          t = {
            WebkitTransition: "webkitTransitionEnd",
            MozTransition: "transitionend",
            OTransition: "oTransitionEnd otransitionend",
            transition: "transitionend"
          },
          n;
        for (n in t) if (e.style[n] !== undefined) return t[n];
      }();
      return e && {
        end: e
      };
    }();
  });
}(window.jQuery), !function (e) {
  "use strict";

  var t = '[data-dismiss="alert"]',
    n = function n(_n) {
      e(_n).on("click", t, this.close);
    };
  n.prototype.close = function (t) {
    function s() {
      i.trigger("closed").remove();
    }
    var n = e(this),
      r = n.attr("data-target"),
      i;
    r || (r = n.attr("href"), r = r && r.replace(/.*(?=#[^\s]*$)/, "")), i = e(r), t && t.preventDefault(), i.length || (i = n.hasClass("alert") ? n : n.parent()), i.trigger(t = e.Event("close"));
    if (t.isDefaultPrevented()) return;
    i.removeClass("in"), e.support.transition && i.hasClass("fade") ? i.on(e.support.transition.end, s) : s();
  }, e.fn.alert = function (t) {
    return this.each(function () {
      var r = e(this),
        i = r.data("alert");
      i || r.data("alert", i = new n(this)), typeof t == "string" && i[t].call(r);
    });
  }, e.fn.alert.Constructor = n, e(function () {
    e("body").on("click.alert.data-api", t, n.prototype.close);
  });
}(window.jQuery), !function (e) {
  "use strict";

  var t = function t(_t, n) {
    this.$element = e(_t), this.options = e.extend({}, e.fn.button.defaults, n);
  };
  t.prototype.setState = function (e) {
    var t = "disabled",
      n = this.$element,
      r = n.data(),
      i = n.is("input") ? "val" : "html";
    e += "Text", r.resetText || n.data("resetText", n[i]()), n[i](r[e] || this.options[e]), setTimeout(function () {
      e == "loadingText" ? n.addClass(t).attr(t, t) : n.removeClass(t).removeAttr(t);
    }, 0);
  }, t.prototype.toggle = function () {
    var e = this.$element.closest('[data-toggle="buttons-radio"]');
    e && e.find(".active").removeClass("active"), this.$element.toggleClass("active");
  }, e.fn.button = function (n) {
    return this.each(function () {
      var r = e(this),
        i = r.data("button"),
        s = _typeof(n) == "object" && n;
      i || r.data("button", i = new t(this, s)), n == "toggle" ? i.toggle() : n && i.setState(n);
    });
  }, e.fn.button.defaults = {
    loadingText: "loading..."
  }, e.fn.button.Constructor = t, e(function () {
    e("body").on("click.button.data-api", "[data-toggle^=button]", function (t) {
      var n = e(t.target);
      n.hasClass("btn") || (n = n.closest(".btn")), n.button("toggle");
    });
  });
}(window.jQuery), !function (e) {
  "use strict";

  var t = function t(_t2, n) {
    this.$element = e(_t2), this.options = n, this.options.slide && this.slide(this.options.slide), this.options.pause == "hover" && this.$element.on("mouseenter", e.proxy(this.pause, this)).on("mouseleave", e.proxy(this.cycle, this));
  };
  t.prototype = {
    cycle: function cycle(t) {
      return t || (this.paused = !1), this.options.interval && !this.paused && (this.interval = setInterval(e.proxy(this.next, this), this.options.interval)), this;
    },
    to: function to(t) {
      var n = this.$element.find(".item.active"),
        r = n.parent().children(),
        i = r.index(n),
        s = this;
      if (t > r.length - 1 || t < 0) return;
      return this.sliding ? this.$element.one("slid", function () {
        s.to(t);
      }) : i == t ? this.pause().cycle() : this.slide(t > i ? "next" : "prev", e(r[t]));
    },
    pause: function pause(t) {
      return t || (this.paused = !0), this.$element.find(".next, .prev").length && e.support.transition.end && (this.$element.trigger(e.support.transition.end), this.cycle()), clearInterval(this.interval), this.interval = null, this;
    },
    next: function next() {
      if (this.sliding) return;
      return this.slide("next");
    },
    prev: function prev() {
      if (this.sliding) return;
      return this.slide("prev");
    },
    slide: function slide(t, n) {
      var r = this.$element.find(".item.active"),
        i = n || r[t](),
        s = this.interval,
        o = t == "next" ? "left" : "right",
        u = t == "next" ? "first" : "last",
        a = this,
        f = e.Event("slide", {
          relatedTarget: i[0]
        });
      this.sliding = !0, s && this.pause(), i = i.length ? i : this.$element.find(".item")[u]();
      if (i.hasClass("active")) return;
      if (e.support.transition && this.$element.hasClass("slide")) {
        this.$element.trigger(f);
        if (f.isDefaultPrevented()) return;
        i.addClass(t), i[0].offsetWidth, r.addClass(o), i.addClass(o), this.$element.one(e.support.transition.end, function () {
          i.removeClass([t, o].join(" ")).addClass("active"), r.removeClass(["active", o].join(" ")), a.sliding = !1, setTimeout(function () {
            a.$element.trigger("slid");
          }, 0);
        });
      } else {
        this.$element.trigger(f);
        if (f.isDefaultPrevented()) return;
        r.removeClass("active"), i.addClass("active"), this.sliding = !1, this.$element.trigger("slid");
      }
      return s && this.cycle(), this;
    }
  }, e.fn.carousel = function (n) {
    return this.each(function () {
      var r = e(this),
        i = r.data("carousel"),
        s = e.extend({}, e.fn.carousel.defaults, _typeof(n) == "object" && n),
        o = typeof n == "string" ? n : s.slide;
      i || r.data("carousel", i = new t(this, s)), typeof n == "number" ? i.to(n) : o ? i[o]() : s.interval && i.cycle();
    });
  }, e.fn.carousel.defaults = {
    interval: 5e3,
    pause: "hover"
  }, e.fn.carousel.Constructor = t, e(function () {
    e("body").on("click.carousel.data-api", "[data-slide]", function (t) {
      var n = e(this),
        r,
        i = e(n.attr("data-target") || (r = n.attr("href")) && r.replace(/.*(?=#[^\s]+$)/, "")),
        s = !i.data("modal") && e.extend({}, i.data(), n.data());
      i.carousel(s), t.preventDefault();
    });
  });
}(window.jQuery), !function (e) {
  "use strict";

  var t = function t(_t3, n) {
    this.$element = e(_t3), this.options = e.extend({}, e.fn.collapse.defaults, n), this.options.parent && (this.$parent = e(this.options.parent)), this.options.toggle && this.toggle();
  };
  t.prototype = {
    constructor: t,
    dimension: function dimension() {
      var e = this.$element.hasClass("width");
      return e ? "width" : "height";
    },
    show: function show() {
      var t, n, r, i;
      if (this.transitioning) return;
      t = this.dimension(), n = e.camelCase(["scroll", t].join("-")), r = this.$parent && this.$parent.find("> .accordion-group > .in");
      if (r && r.length) {
        i = r.data("collapse");
        if (i && i.transitioning) return;
        r.collapse("hide"), i || r.data("collapse", null);
      }
      this.$element[t](0), this.transition("addClass", e.Event("show"), "shown"), e.support.transition && this.$element[t](this.$element[0][n]);
    },
    hide: function hide() {
      var t;
      if (this.transitioning) return;
      t = this.dimension(), this.reset(this.$element[t]()), this.transition("removeClass", e.Event("hide"), "hidden"), this.$element[t](0);
    },
    reset: function reset(e) {
      var t = this.dimension();
      return this.$element.removeClass("collapse")[t](e || "auto")[0].offsetWidth, this.$element[e !== null ? "addClass" : "removeClass"]("collapse"), this;
    },
    transition: function transition(t, n, r) {
      var i = this,
        s = function s() {
          n.type == "show" && i.reset(), i.transitioning = 0, i.$element.trigger(r);
        };
      this.$element.trigger(n);
      if (n.isDefaultPrevented()) return;
      this.transitioning = 1, this.$element[t]("in"), e.support.transition && this.$element.hasClass("collapse") ? this.$element.one(e.support.transition.end, s) : s();
    },
    toggle: function toggle() {
      this[this.$element.hasClass("in") ? "hide" : "show"]();
    }
  }, e.fn.collapse = function (n) {
    return this.each(function () {
      var r = e(this),
        i = r.data("collapse"),
        s = _typeof(n) == "object" && n;
      i || r.data("collapse", i = new t(this, s)), typeof n == "string" && i[n]();
    });
  }, e.fn.collapse.defaults = {
    toggle: !0
  }, e.fn.collapse.Constructor = t, e(function () {
    e("body").on("click.collapse.data-api", "[data-toggle=collapse]", function (t) {
      var n = e(this),
        r,
        i = n.attr("data-target") || t.preventDefault() || (r = n.attr("href")) && r.replace(/.*(?=#[^\s]+$)/, ""),
        s = e(i).data("collapse") ? "toggle" : n.data();
      n[e(i).hasClass("in") ? "addClass" : "removeClass"]("collapsed"), e(i).collapse(s);
    });
  });
}(window.jQuery), !function (e) {
  "use strict";

  function r() {
    i(e(t)).removeClass("open");
  }
  function i(t) {
    var n = t.attr("data-target"),
      r;
    return n || (n = t.attr("href"), n = n && /#/.test(n) && n.replace(/.*(?=#[^\s]*$)/, "")), r = e(n), r.length || (r = t.parent()), r;
  }
  var t = "[data-toggle=dropdown]",
    n = function n(t) {
      var n = e(t).on("click.dropdown.data-api", this.toggle);
      e("html").on("click.dropdown.data-api", function () {
        n.parent().removeClass("open");
      });
    };
  n.prototype = {
    constructor: n,
    toggle: function toggle(t) {
      var n = e(this),
        s,
        o;
      if (n.is(".disabled, :disabled")) return;
      return s = i(n), o = s.hasClass("open"), r(), o || (s.toggleClass("open"), n.focus()), !1;
    },
    keydown: function keydown(t) {
      var n, r, s, o, u, a;
      if (!/(38|40|27)/.test(t.keyCode)) return;
      n = e(this), t.preventDefault(), t.stopPropagation();
      if (n.is(".disabled, :disabled")) return;
      o = i(n), u = o.hasClass("open");
      if (!u || u && t.keyCode == 27) return n.click();
      r = e("[role=menu] li:not(.divider) a", o);
      if (!r.length) return;
      a = r.index(r.filter(":focus")), t.keyCode == 38 && a > 0 && a--, t.keyCode == 40 && a < r.length - 1 && a++, ~a || (a = 0), r.eq(a).focus();
    }
  }, e.fn.dropdown = function (t) {
    return this.each(function () {
      var r = e(this),
        i = r.data("dropdown");
      i || r.data("dropdown", i = new n(this)), typeof t == "string" && i[t].call(r);
    });
  }, e.fn.dropdown.Constructor = n, e(function () {
    e("html").on("click.dropdown.data-api touchstart.dropdown.data-api", r), e("body").on("click.dropdown touchstart.dropdown.data-api", ".dropdown form", function (e) {
      e.stopPropagation();
    }).on("click.dropdown.data-api touchstart.dropdown.data-api", t, n.prototype.toggle).on("keydown.dropdown.data-api touchstart.dropdown.data-api", t + ", [role=menu]", n.prototype.keydown);
  });
}(window.jQuery), !function (e) {
  "use strict";

  var t = function t(_t4, n) {
    this.options = n, this.$element = e(_t4).delegate('[data-dismiss="modal"]', "click.dismiss.modal", e.proxy(this.hide, this)), this.options.remote && this.$element.find(".modal-body").load(this.options.remote);
  };
  t.prototype = {
    constructor: t,
    toggle: function toggle() {
      return this[this.isShown ? "hide" : "show"]();
    },
    show: function show() {
      var t = this,
        n = e.Event("show");
      this.$element.trigger(n);
      if (this.isShown || n.isDefaultPrevented()) return;
      e("body").addClass("modal-open"), this.isShown = !0, this.escape(), this.backdrop(function () {
        var n = e.support.transition && t.$element.hasClass("fade");
        t.$element.parent().length || t.$element.appendTo(document.body), t.$element.show(), n && t.$element[0].offsetWidth, t.$element.addClass("in").attr("aria-hidden", !1).focus(), t.enforceFocus(), n ? t.$element.one(e.support.transition.end, function () {
          t.$element.trigger("shown");
        }) : t.$element.trigger("shown");
      });
    },
    hide: function hide(t) {
      t && t.preventDefault();
      var n = this;
      t = e.Event("hide"), this.$element.trigger(t);
      if (!this.isShown || t.isDefaultPrevented()) return;
      this.isShown = !1, e("body").removeClass("modal-open"), this.escape(), e(document).off("focusin.modal"), this.$element.removeClass("in").attr("aria-hidden", !0), e.support.transition && this.$element.hasClass("fade") ? this.hideWithTransition() : this.hideModal();
    },
    enforceFocus: function enforceFocus() {
      var t = this;
      e(document).on("focusin.modal", function (e) {
        t.$element[0] !== e.target && !t.$element.has(e.target).length && t.$element.focus();
      });
    },
    escape: function escape() {
      var e = this;
      this.isShown && this.options.keyboard ? this.$element.on("keyup.dismiss.modal", function (t) {
        t.which == 27 && e.hide();
      }) : this.isShown || this.$element.off("keyup.dismiss.modal");
    },
    hideWithTransition: function hideWithTransition() {
      var t = this,
        n = setTimeout(function () {
          t.$element.off(e.support.transition.end), t.hideModal();
        }, 500);
      this.$element.one(e.support.transition.end, function () {
        clearTimeout(n), t.hideModal();
      });
    },
    hideModal: function hideModal(e) {
      this.$element.hide().trigger("hidden"), this.backdrop();
    },
    removeBackdrop: function removeBackdrop() {
      this.$backdrop.remove(), this.$backdrop = null;
    },
    backdrop: function backdrop(t) {
      var n = this,
        r = this.$element.hasClass("fade") ? "fade" : "";
      if (this.isShown && this.options.backdrop) {
        var i = e.support.transition && r;
        this.$backdrop = e('<div class="modal-backdrop ' + r + '" />').appendTo(document.body), this.options.backdrop != "static" && this.$backdrop.click(e.proxy(this.hide, this)), i && this.$backdrop[0].offsetWidth, this.$backdrop.addClass("in"), i ? this.$backdrop.one(e.support.transition.end, t) : t();
      } else !this.isShown && this.$backdrop ? (this.$backdrop.removeClass("in"), e.support.transition && this.$element.hasClass("fade") ? this.$backdrop.one(e.support.transition.end, e.proxy(this.removeBackdrop, this)) : this.removeBackdrop()) : t && t();
    }
  }, e.fn.modal = function (n) {
    return this.each(function () {
      var r = e(this),
        i = r.data("modal"),
        s = e.extend({}, e.fn.modal.defaults, r.data(), _typeof(n) == "object" && n);
      i || r.data("modal", i = new t(this, s)), typeof n == "string" ? i[n]() : s.show && i.show();
    });
  }, e.fn.modal.defaults = {
    backdrop: !0,
    keyboard: !0,
    show: !0
  }, e.fn.modal.Constructor = t, e(function () {
    e("body").on("click.modal.data-api", '[data-toggle="modal"]', function (t) {
      var n = e(this),
        r = n.attr("href"),
        i = e(n.attr("data-target") || r && r.replace(/.*(?=#[^\s]+$)/, "")),
        s = i.data("modal") ? "toggle" : e.extend({
          remote: !/#/.test(r) && r
        }, i.data(), n.data());
      t.preventDefault(), i.modal(s).one("hide", function () {
        n.focus();
      });
    });
  });
}(window.jQuery), !function (e) {
  "use strict";

  var t = function t(e, _t5) {
    this.init("tooltip", e, _t5);
  };
  t.prototype = {
    constructor: t,
    init: function init(t, n, r) {
      var i, s;
      this.type = t, this.$element = e(n), this.options = this.getOptions(r), this.enabled = !0, this.options.trigger == "click" ? this.$element.on("click." + this.type, this.options.selector, e.proxy(this.toggle, this)) : this.options.trigger != "manual" && (i = this.options.trigger == "hover" ? "mouseenter" : "focus", s = this.options.trigger == "hover" ? "mouseleave" : "blur", this.$element.on(i + "." + this.type, this.options.selector, e.proxy(this.enter, this)), this.$element.on(s + "." + this.type, this.options.selector, e.proxy(this.leave, this))), this.options.selector ? this._options = e.extend({}, this.options, {
        trigger: "manual",
        selector: ""
      }) : this.fixTitle();
    },
    getOptions: function getOptions(t) {
      return t = e.extend({}, e.fn[this.type].defaults, t, this.$element.data()), t.delay && typeof t.delay == "number" && (t.delay = {
        show: t.delay,
        hide: t.delay
      }), t;
    },
    enter: function enter(t) {
      var n = e(t.currentTarget)[this.type](this._options).data(this.type);
      if (!n.options.delay || !n.options.delay.show) return n.show();
      clearTimeout(this.timeout), n.hoverState = "in", this.timeout = setTimeout(function () {
        n.hoverState == "in" && n.show();
      }, n.options.delay.show);
    },
    leave: function leave(t) {
      var n = e(t.currentTarget)[this.type](this._options).data(this.type);
      this.timeout && clearTimeout(this.timeout);
      if (!n.options.delay || !n.options.delay.hide) return n.hide();
      n.hoverState = "out", this.timeout = setTimeout(function () {
        n.hoverState == "out" && n.hide();
      }, n.options.delay.hide);
    },
    show: function show() {
      var e, t, n, r, i, s, o;
      if (this.hasContent() && this.enabled) {
        e = this.tip(), this.setContent(), this.options.animation && e.addClass("fade"), s = typeof this.options.placement == "function" ? this.options.placement.call(this, e[0], this.$element[0]) : this.options.placement, t = /in/.test(s), e.remove().css({
          top: 0,
          left: 0,
          display: "block"
        }).appendTo(t ? this.$element : document.body), n = this.getPosition(t), r = e[0].offsetWidth, i = e[0].offsetHeight;
        switch (t ? s.split(" ")[1] : s) {
          case "bottom":
            o = {
              top: n.top + n.height,
              left: n.left + n.width / 2 - r / 2
            };
            break;
          case "top":
            o = {
              top: n.top - i,
              left: n.left + n.width / 2 - r / 2
            };
            break;
          case "left":
            o = {
              top: n.top + n.height / 2 - i / 2,
              left: n.left - r
            };
            break;
          case "right":
            o = {
              top: n.top + n.height / 2 - i / 2,
              left: n.left + n.width
            };
        }
        e.css(o).addClass(s).addClass("in");
      }
    },
    setContent: function setContent() {
      var e = this.tip(),
        t = this.getTitle();
      e.find(".tooltip-inner")[this.options.html ? "html" : "text"](t), e.removeClass("fade in top bottom left right");
    },
    hide: function hide() {
      function r() {
        var t = setTimeout(function () {
          n.off(e.support.transition.end).remove();
        }, 500);
        n.one(e.support.transition.end, function () {
          clearTimeout(t), n.remove();
        });
      }
      var t = this,
        n = this.tip();
      return n.removeClass("in"), e.support.transition && this.$tip.hasClass("fade") ? r() : n.remove(), this;
    },
    fixTitle: function fixTitle() {
      var e = this.$element;
      (e.attr("title") || typeof e.attr("data-original-title") != "string") && e.attr("data-original-title", e.attr("title") || "").removeAttr("title");
    },
    hasContent: function hasContent() {
      return this.getTitle();
    },
    getPosition: function getPosition(t) {
      return e.extend({}, t ? {
        top: 0,
        left: 0
      } : this.$element.offset(), {
        width: this.$element[0].offsetWidth,
        height: this.$element[0].offsetHeight
      });
    },
    getTitle: function getTitle() {
      var e,
        t = this.$element,
        n = this.options;
      return e = t.attr("data-original-title") || (typeof n.title == "function" ? n.title.call(t[0]) : n.title), e;
    },
    tip: function tip() {
      return this.$tip = this.$tip || e(this.options.template);
    },
    validate: function validate() {
      this.$element[0].parentNode || (this.hide(), this.$element = null, this.options = null);
    },
    enable: function enable() {
      this.enabled = !0;
    },
    disable: function disable() {
      this.enabled = !1;
    },
    toggleEnabled: function toggleEnabled() {
      this.enabled = !this.enabled;
    },
    toggle: function toggle() {
      this[this.tip().hasClass("in") ? "hide" : "show"]();
    },
    destroy: function destroy() {
      this.hide().$element.off("." + this.type).removeData(this.type);
    }
  }, e.fn.tooltip = function (n) {
    return this.each(function () {
      var r = e(this),
        i = r.data("tooltip"),
        s = _typeof(n) == "object" && n;
      i || r.data("tooltip", i = new t(this, s)), typeof n == "string" && i[n]();
    });
  }, e.fn.tooltip.Constructor = t, e.fn.tooltip.defaults = {
    animation: !0,
    placement: "top",
    selector: !1,
    template: '<div class="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>',
    trigger: "hover",
    title: "",
    delay: 0,
    html: !0
  };
}(window.jQuery), !function (e) {
  "use strict";

  var t = function t(e, _t6) {
    this.init("popover", e, _t6);
  };
  t.prototype = e.extend({}, e.fn.tooltip.Constructor.prototype, {
    constructor: t,
    setContent: function setContent() {
      var e = this.tip(),
        t = this.getTitle(),
        n = this.getContent();
      e.find(".popover-title")[this.options.html ? "html" : "text"](t), e.find(".popover-content > *")[this.options.html ? "html" : "text"](n), e.removeClass("fade top bottom left right in");
    },
    hasContent: function hasContent() {
      return this.getTitle() || this.getContent();
    },
    getContent: function getContent() {
      var e,
        t = this.$element,
        n = this.options;
      return e = t.attr("data-content") || (typeof n.content == "function" ? n.content.call(t[0]) : n.content), e;
    },
    tip: function tip() {
      return this.$tip || (this.$tip = e(this.options.template)), this.$tip;
    },
    destroy: function destroy() {
      this.hide().$element.off("." + this.type).removeData(this.type);
    }
  }), e.fn.popover = function (n) {
    return this.each(function () {
      var r = e(this),
        i = r.data("popover"),
        s = _typeof(n) == "object" && n;
      i || r.data("popover", i = new t(this, s)), typeof n == "string" && i[n]();
    });
  }, e.fn.popover.Constructor = t, e.fn.popover.defaults = e.extend({}, e.fn.tooltip.defaults, {
    placement: "right",
    trigger: "click",
    content: "",
    template: '<div class="popover"><div class="arrow"></div><div class="popover-inner"><h3 class="popover-title"></h3><div class="popover-content"><p></p></div></div></div>'
  });
}(window.jQuery), !function (e) {
  "use strict";

  function t(t, n) {
    var r = e.proxy(this.process, this),
      i = e(t).is("body") ? e(window) : e(t),
      s;
    this.options = e.extend({}, e.fn.scrollspy.defaults, n), this.$scrollElement = i.on("scroll.scroll-spy.data-api", r), this.selector = (this.options.target || (s = e(t).attr("href")) && s.replace(/.*(?=#[^\s]+$)/, "") || "") + " .nav li > a", this.$body = e("body"), this.refresh(), this.process();
  }
  t.prototype = {
    constructor: t,
    refresh: function refresh() {
      var t = this,
        n;
      this.offsets = e([]), this.targets = e([]), n = this.$body.find(this.selector).map(function () {
        var t = e(this),
          n = t.data("target") || t.attr("href"),
          r = /^#\w/.test(n) && e(n);
        return r && r.length && [[r.position().top, n]] || null;
      }).sort(function (e, t) {
        return e[0] - t[0];
      }).each(function () {
        t.offsets.push(this[0]), t.targets.push(this[1]);
      });
    },
    process: function process() {
      var e = this.$scrollElement.scrollTop() + this.options.offset,
        t = this.$scrollElement[0].scrollHeight || this.$body[0].scrollHeight,
        n = t - this.$scrollElement.height(),
        r = this.offsets,
        i = this.targets,
        s = this.activeTarget,
        o;
      if (e >= n) return s != (o = i.last()[0]) && this.activate(o);
      for (o = r.length; o--;) s != i[o] && e >= r[o] && (!r[o + 1] || e <= r[o + 1]) && this.activate(i[o]);
    },
    activate: function activate(t) {
      var n, r;
      this.activeTarget = t, e(this.selector).parent(".active").removeClass("active"), r = this.selector + '[data-target="' + t + '"],' + this.selector + '[href="' + t + '"]', n = e(r).parent("li").addClass("active"), n.parent(".dropdown-menu").length && (n = n.closest("li.dropdown").addClass("active")), n.trigger("activate");
    }
  }, e.fn.scrollspy = function (n) {
    return this.each(function () {
      var r = e(this),
        i = r.data("scrollspy"),
        s = _typeof(n) == "object" && n;
      i || r.data("scrollspy", i = new t(this, s)), typeof n == "string" && i[n]();
    });
  }, e.fn.scrollspy.Constructor = t, e.fn.scrollspy.defaults = {
    offset: 10
  }, e(window).on("load", function () {
    e('[data-spy="scroll"]').each(function () {
      var t = e(this);
      t.scrollspy(t.data());
    });
  });
}(window.jQuery), !function (e) {
  "use strict";

  var t = function t(_t7) {
    this.element = e(_t7);
  };
  t.prototype = {
    constructor: t,
    show: function show() {
      var t = this.element,
        n = t.closest("ul:not(.dropdown-menu)"),
        r = t.attr("data-target"),
        i,
        s,
        o;
      r || (r = t.attr("href"), r = r && r.replace(/.*(?=#[^\s]*$)/, ""));
      if (t.parent("li").hasClass("active")) return;
      i = n.find(".active a").last()[0], o = e.Event("show", {
        relatedTarget: i
      }), t.trigger(o);
      if (o.isDefaultPrevented()) return;
      s = e(r), this.activate(t.parent("li"), n), this.activate(s, s.parent(), function () {
        t.trigger({
          type: "shown",
          relatedTarget: i
        });
      });
    },
    activate: function activate(t, n, r) {
      function o() {
        i.removeClass("active").find("> .dropdown-menu > .active").removeClass("active"), t.addClass("active"), s ? (t[0].offsetWidth, t.addClass("in")) : t.removeClass("fade"), t.parent(".dropdown-menu") && t.closest("li.dropdown").addClass("active"), r && r();
      }
      var i = n.find("> .active"),
        s = r && e.support.transition && i.hasClass("fade");
      s ? i.one(e.support.transition.end, o) : o(), i.removeClass("in");
    }
  }, e.fn.tab = function (n) {
    return this.each(function () {
      var r = e(this),
        i = r.data("tab");
      i || r.data("tab", i = new t(this)), typeof n == "string" && i[n]();
    });
  }, e.fn.tab.Constructor = t, e(function () {
    e("body").on("click.tab.data-api", '[data-toggle="tab"], [data-toggle="pill"]', function (t) {
      t.preventDefault(), e(this).tab("show");
    });
  });
}(window.jQuery), !function (e) {
  "use strict";

  var t = function t(_t8, n) {
    this.$element = e(_t8), this.options = e.extend({}, e.fn.typeahead.defaults, n), this.matcher = this.options.matcher || this.matcher, this.sorter = this.options.sorter || this.sorter, this.highlighter = this.options.highlighter || this.highlighter, this.updater = this.options.updater || this.updater, this.$menu = e(this.options.menu).appendTo("body"), this.source = this.options.source, this.shown = !1, this.listen();
  };
  t.prototype = {
    constructor: t,
    select: function select() {
      var e = this.$menu.find(".active").attr("data-value");
      return this.$element.val(this.updater(e)).change(), this.hide();
    },
    updater: function updater(e) {
      return e;
    },
    show: function show() {
      var t = e.extend({}, this.$element.offset(), {
        height: this.$element[0].offsetHeight
      });
      return this.$menu.css({
        top: t.top + t.height,
        left: t.left
      }), this.$menu.show(), this.shown = !0, this;
    },
    hide: function hide() {
      return this.$menu.hide(), this.shown = !1, this;
    },
    lookup: function lookup(t) {
      var n;
      return this.query = this.$element.val(), !this.query || this.query.length < this.options.minLength ? this.shown ? this.hide() : this : (n = e.isFunction(this.source) ? this.source(this.query, e.proxy(this.process, this)) : this.source, n ? this.process(n) : this);
    },
    process: function process(t) {
      var n = this;
      return t = e.grep(t, function (e) {
        return n.matcher(e);
      }), t = this.sorter(t), t.length ? this.render(t.slice(0, this.options.items)).show() : this.shown ? this.hide() : this;
    },
    matcher: function matcher(e) {
      return ~e.toLowerCase().indexOf(this.query.toLowerCase());
    },
    sorter: function sorter(e) {
      var t = [],
        n = [],
        r = [],
        i;
      while (i = e.shift()) i.toLowerCase().indexOf(this.query.toLowerCase()) ? ~i.indexOf(this.query) ? n.push(i) : r.push(i) : t.push(i);
      return t.concat(n, r);
    },
    highlighter: function highlighter(e) {
      var t = this.query.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, "\\$&");
      return e.replace(new RegExp("(" + t + ")", "ig"), function (e, t) {
        return "<strong>" + t + "</strong>";
      });
    },
    render: function render(t) {
      var n = this;
      return t = e(t).map(function (t, r) {
        return t = e(n.options.item).attr("data-value", r), t.find("a").html(n.highlighter(r)), t[0];
      }), t.first().addClass("active"), this.$menu.html(t), this;
    },
    next: function next(t) {
      var n = this.$menu.find(".active").removeClass("active"),
        r = n.next();
      r.length || (r = e(this.$menu.find("li")[0])), r.addClass("active");
    },
    prev: function prev(e) {
      var t = this.$menu.find(".active").removeClass("active"),
        n = t.prev();
      n.length || (n = this.$menu.find("li").last()), n.addClass("active");
    },
    listen: function listen() {
      this.$element.on("blur", e.proxy(this.blur, this)).on("keypress", e.proxy(this.keypress, this)).on("keyup", e.proxy(this.keyup, this)), (e.browser.chrome || e.browser.webkit || e.browser.msie) && this.$element.on("keydown", e.proxy(this.keydown, this)), this.$menu.on("click", e.proxy(this.click, this)).on("mouseenter", "li", e.proxy(this.mouseenter, this));
    },
    move: function move(e) {
      if (!this.shown) return;
      switch (e.keyCode) {
        case 9:
        case 13:
        case 27:
          e.preventDefault();
          break;
        case 38:
          e.preventDefault(), this.prev();
          break;
        case 40:
          e.preventDefault(), this.next();
      }
      e.stopPropagation();
    },
    keydown: function keydown(t) {
      this.suppressKeyPressRepeat = !~e.inArray(t.keyCode, [40, 38, 9, 13, 27]), this.move(t);
    },
    keypress: function keypress(e) {
      if (this.suppressKeyPressRepeat) return;
      this.move(e);
    },
    keyup: function keyup(e) {
      switch (e.keyCode) {
        case 40:
        case 38:
          break;
        case 9:
        case 13:
          if (!this.shown) return;
          this.select();
          break;
        case 27:
          if (!this.shown) return;
          this.hide();
          break;
        default:
          this.lookup();
      }
      e.stopPropagation(), e.preventDefault();
    },
    blur: function blur(e) {
      var t = this;
      setTimeout(function () {
        t.hide();
      }, 150);
    },
    click: function click(e) {
      e.stopPropagation(), e.preventDefault(), this.select();
    },
    mouseenter: function mouseenter(t) {
      this.$menu.find(".active").removeClass("active"), e(t.currentTarget).addClass("active");
    }
  }, e.fn.typeahead = function (n) {
    return this.each(function () {
      var r = e(this),
        i = r.data("typeahead"),
        s = _typeof(n) == "object" && n;
      i || r.data("typeahead", i = new t(this, s)), typeof n == "string" && i[n]();
    });
  }, e.fn.typeahead.defaults = {
    source: [],
    items: 8,
    menu: '<ul class="typeahead dropdown-menu"></ul>',
    item: '<li><a href="#"></a></li>',
    minLength: 1
  }, e.fn.typeahead.Constructor = t, e(function () {
    e("body").on("focus.typeahead.data-api", '[data-provide="typeahead"]', function (t) {
      var n = e(this);
      if (n.data("typeahead")) return;
      t.preventDefault(), n.typeahead(n.data());
    });
  });
}(window.jQuery), !function (e) {
  "use strict";

  var t = function t(_t9, n) {
    this.options = e.extend({}, e.fn.affix.defaults, n), this.$window = e(window).on("scroll.affix.data-api", e.proxy(this.checkPosition, this)), this.$element = e(_t9), this.checkPosition();
  };
  t.prototype.checkPosition = function () {
    if (!this.$element.is(":visible")) return;
    var t = e(document).height(),
      n = this.$window.scrollTop(),
      r = this.$element.offset(),
      i = this.options.offset,
      s = i.bottom,
      o = i.top,
      u = "affix affix-top affix-bottom",
      a;
    _typeof(i) != "object" && (s = o = i), typeof o == "function" && (o = i.top()), typeof s == "function" && (s = i.bottom()), a = this.unpin != null && n + this.unpin <= r.top ? !1 : s != null && r.top + this.$element.height() >= t - s ? "bottom" : o != null && n <= o ? "top" : !1;
    if (this.affixed === a) return;
    this.affixed = a, this.unpin = a == "bottom" ? r.top - n : null, this.$element.removeClass(u).addClass("affix" + (a ? "-" + a : ""));
  }, e.fn.affix = function (n) {
    return this.each(function () {
      var r = e(this),
        i = r.data("affix"),
        s = _typeof(n) == "object" && n;
      i || r.data("affix", i = new t(this, s)), typeof n == "string" && i[n]();
    });
  }, e.fn.affix.Constructor = t, e.fn.affix.defaults = {
    offset: 0
  }, e(window).on("load", function () {
    e('[data-spy="affix"]').each(function () {
      var t = e(this),
        n = t.data();
      n.offset = n.offset || {}, n.offsetBottom && (n.offset.bottom = n.offsetBottom), n.offsetTop && (n.offset.top = n.offsetTop), t.affix(n);
    });
  });
}(window.jQuery);